import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import AppBarHeader from "../constants/appbar/AppBar";
import Particles from "../home/ParticlesConfig";

function HomePage() {
  const theme = useTheme();
  return (
    <div>
      <AppBarHeader />

      <Grid
        spacing={2}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "80vh" }}
      >
        <Grid item xs={6}>
          <Box
            sx={{
              textAlign: "center",
              p: "16px",
              mt: "8px",
              width: "75vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card variant="outlined" style={{ textAlign: "center" }}>
              <CardContent
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  p: "16px",
                }}
              >
                <Typography variant="h5" component="h2" gutterBottom>
                  Privacy Policy
                </Typography>
                <Divider sx={{ m: "20px 0" }}>
                  <Typography variant="h6">Introduction</Typography>
                </Divider>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  Welcome to the policy page for Equis, the Enhanced Viewer and
                  Creator Experience! Our Google Chrome extension serves as a
                  gateway to a suite of add-ons designed to improve and
                  personalise user experiences on popular streaming and video
                  platforms such as Twitch, YouTube, and Reddit. Equis provides
                  a customizable interface with theming options and general
                  accessibility settings upon installation.
                </Typography>

                <Typography variant="body1" color={"secondary"} gutterBottom>
                  Date of Last Update: May 13, 2023
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  By using Equis and its associated add-ons, users agree to the
                  terms and conditions outlined in this policy. Your continued
                  use of the extension and its features constitutes your
                  acceptance of these terms, as well as any updates or revisions
                  that may be made in the future. If you do not agree with any
                  part of this policy, please discontinue use of the extension
                  and its add-ons.
                </Typography>
                <Divider sx={{ m: "20px 0" }}>
                  <Typography variant="h6"> Policy Acceptance</Typography>
                </Divider>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  Please note that this policy was last updated on May 13, 2023.
                  We encourage users to regularly check this page for any
                  updates or changes to our policies and practices.
                </Typography>
                <Divider sx={{ m: "20px 0" }}>
                  <Typography variant="h6">Definitions</Typography>
                </Divider>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  Add-ons: Additional features or functionalities that can be
                  installed and integrated into the Equis extension to enhance
                  user experience on various streaming and video platforms.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  Data Sync: A feature that allows users to maintain consistent
                  settings across devices by using their connected account
                  information.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  Analytics Data: Usage and performance information collected
                  from users who have opted in to data sharing, which is used to
                  analyse and improve the functionality of our add-ons.
                </Typography>
                <Divider sx={{ m: "20px 0" }}>
                  <Typography variant="h6">Governing Law</Typography>
                </Divider>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  This policy, as well as any disputes arising from or related
                  to the use of Equis and its add-ons, shall be governed by and
                  construed in accordance with the laws of [your jurisdiction].
                  Users agree to submit to the exclusive jurisdiction of the
                  courts located within [your jurisdiction] to resolve any legal
                  matters arising from this policy or the use of the extension
                  and its add-ons.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  We offer various add-ons tailored to different platforms that
                  enhance user experience by providing advanced playback
                  options, customizable content filtering, chat enhancements,
                  privacy settings, and seamless content integration between
                  platforms. Additionally, our add-ons also focus on improving
                  accessibility with features such as live captioning for
                  streams and chat translation tools.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  By using Equis and its add-ons, users can enjoy a more
                  immersive and personalised experience across various streaming
                  and video platforms. Please read the following sections to
                  understand our policies regarding data collection, usage,
                  consent, data retention, security, third-party services, and
                  how to contact us with any concerns or questions.
                </Typography>
                <Divider sx={{ m: "20px 0" }}>
                  <Typography variant="h6">
                    Section 6: Data Collection, Usage, and Consent
                  </Typography>
                </Divider>
                <Typography variant="body1" color={"secondary"} gutterBottom>
                  6.1 Data Collection
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  Equis collects minimal data from its users to ensure an
                  optimal user experience. The primary data collected is the
                  username of the sites connected, which enables features like
                  data sync, allowing users to maintain consistent settings
                  across devices. Additionally, there is an option in the
                  general settings for users to enable data sharing for
                  analytics purposes. When this option is enabled, the only data
                  shared is related to the usage and performance of the add-ons.
                </Typography>

                <Typography variant="body1" color={"secondary"} gutterBottom>
                  6.2 Data Usage
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  The username information collected is used exclusively to
                  facilitate the data sync feature. The analytics data shared,
                  when enabled by the user, helps us improve our add-ons by
                  understanding their popularity, usage patterns, and
                  performance metrics. This information enables us to make
                  data-driven decisions in enhancing and maintaining our suite
                  of add-ons.
                </Typography>

                <Typography variant="body1" color={"secondary"} gutterBottom>
                  6.3 User Consent
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  Equis is committed to providing users with control over their
                  data. By default, any communication with our servers, apart
                  from token refreshing, is optional. Users can toggle off all
                  data sharing options in the settings, ensuring they have full
                  control over their data and its usage. We respect our users'
                  privacy and only collect and use data for the purposes
                  specified in this policy.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  Please review the following sections to learn more about our
                  policies regarding data retention, security, third-party
                  services, and how to contact us with any concerns or
                  questions.
                </Typography>
                <Divider sx={{ m: "20px 0" }}>
                  <Typography variant="h6">
                    Section 7: Data Retention and Security
                  </Typography>
                </Divider>
                <Typography variant="body1" color={"secondary"} gutterBottom>
                  7.1 Data Retention
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  User data is retained only as long as the user has their
                  accounts connected to our extension. Once a user disconnects
                  their account, the associated data becomes obsolete and is no
                  longer retained.
                </Typography>

                <Typography variant="body1" color={"secondary"} gutterBottom>
                  7.2 Data Security
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  To ensure the security of collected user data, we use a
                  trusted cloud service provider that employs industry-standard
                  security measures. These measures include encryption of data
                  during transit and storage, maintaining secure servers with
                  regular security updates, and employing access controls to
                  prevent unauthorized access to the data. By using a reputable
                  provider, we ensure that user data remains protected and
                  secure.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  Please review the following sections to learn more about our
                  policies regarding third-party services and how to contact us
                  with any concerns or questions.
                </Typography>
                <Divider sx={{ m: "20px 0" }}>
                  <Typography variant="h6">
                    Section 8: Third-Party Services and Websites
                  </Typography>
                </Divider>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  Currently, our extension does not include links to third-party
                  services or websites, nor do we integrate any third-party
                  services for features or functionalities. However, it is worth
                  noting that our suite of add-ons is not limited to our own
                  development. In the future, some add-ons could be developed by
                  third parties, potentially introducing additional services or
                  integrations. Should any changes occur in this regard, we will
                  update our policy accordingly to reflect the inclusion of
                  third-party services and their respective privacy practices.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  Please review the following section to learn more about how to
                  contact us with any concerns or questions.
                </Typography>
                <Divider sx={{ m: "20px 0" }}>
                  <Typography variant="h6">
                    {" "}
                    Section 9: Contact Information
                  </Typography>
                </Divider>
                <Typography variant="body1" color={"secondary"} gutterBottom>
                  9.1 Support and Assistance
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  As a growing extension, our support resources are currently
                  limited. However, we are dedicated to addressing user feedback
                  and providing assistance where possible. For each add-on, we
                  have set up dedicated feedback channels accessible through the
                  add-on's options page when clicked on via the installed
                  add-ons page. Users can use these channels to ask questions,
                  share concerns, or request assistance.
                </Typography>

                <Typography variant="body1" color={"secondary"} gutterBottom>
                  9.2 Reporting Privacy Concerns
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  To report any privacy concerns or potential violations, we
                  encourage users to use Google's extension feedback system.
                  Users can reach out to the developers directly through this
                  system or leave feedback on the extension's page on the Chrome
                  Web Store. This will help us promptly address any issues and
                  ensure the ongoing privacy and security of our users.
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  color={"text.secondary"}
                >
                  Thank you for using Equis and its add-ons. We are committed to
                  providing an enhanced viewer and creator experience while
                  respecting your privacy and data.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Particles id="tsparticles" />
    </div>
  );
}

export default HomePage;
