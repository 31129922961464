import { createTheme } from "@mui/material/styles";
import darkScrollbar from "@mui/material/darkScrollbar";

export const equisDark = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#f9cc0b",
      light: "#f3df8d",
      dark: "#ffda42",
      contrastText: "rgba(14,9,47,0.87)",
    },
    secondary: {
      main: "#ceceff",
      dark: "#b2b2ff",
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#ff6e00",
    },
    info: {
      main: "#0080de",
    },
    background: {
      default: "#10100e",
      paper: "#19181b",
    },
  },
  typography: {
    h4: {
      fontSize: 30,
      fontWeight: 700,
    },
    button: {
      fontFamily: "Sora",
      fontWeight: 400,
      fontSize: "0.9rem",
    },
    h6: {
      fontFamily: "Sora",
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: "Sora",
      fontSize: "1rem",
      lineHeight: 1.7,
    },
    subtitle2: {
      fontFamily: "Sora",
    },
    caption: {
      fontFamily: "Sora",
    },
    h5: {
      fontFamily: "Sora",
      fontWeight: 500,
      fontSize: "1.6rem",
      letterSpacing: "0.01em",
    },
    fontFamily: "Sora",
    fontWeightRegular: 400,
    fontWeightBold: 600,
    fontWeightMedium: 500,
    h3: {
      fontSize: 38,
      fontWeight: 600,
    },
    h2: {
      fontSize: 42,
      fontWeight: 800,
    },
    h1: {
      fontFamily: "MuseoModerno",
      fontSize: 45,
      fontWeight: 600,
      lineHeight: 0.6,
    },
  },
  overrides: {
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
          transform: "translateX(16px)",
          color: "#fff",
          "& + $track": {
            opacity: 1,
            border: "none",
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: "1px solid #bdbdbd",
        backgroundColor: "#fafafa",
        opacity: 1,
        transition:
          "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },
    },
  },
  props: {
    MuiAppBar: {
      color: "transparent",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          "&:visited": {
            color: "inherit",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: themeParam.palette.mode === "dark" ? darkScrollbar() : null,
      }),
    },
  },
});
