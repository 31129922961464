import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { equisDark } from "./theme";

// Import your components for different pages
import HomePage from "./pages/home/HomePage";
import PolicyPage from "./pages/policy/PolicyPage";

function App() {
  return (
    <ThemeProvider theme={equisDark}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* Route for the home page */}
          <Route path="/" element={<HomePage />} />

          {/* Route for the privacy policy page */}
          <Route path="/privacy-policy" element={<PolicyPage />} />

          {/* Add more routes for other pages if needed */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
