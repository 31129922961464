import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function CustomAppBar() {
  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar>
        <Typography
          variant="h1"
          component={Link}
          to="/"
          color="primary"
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
            justifySelf: "center",
            textDecoration: "none",
          }}
        >
          x
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default CustomAppBar;
