import React from "react";
import { Box, Button, Grid, Toolbar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import Particles from "./ParticlesConfig";
import AppBarHeader from "../constants/appbar/AppBar";

function HomePage() {
  const theme = useTheme();
  return (
    <div>
      <AppBarHeader />

      <Grid
        spacing={2}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "80vh" }}
      >
        <Grid item xs={6}>
          <Box
            sx={{
              textAlign: "center",
              p: "16px",
              width: "75vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h3">
              Enhance your Streaming and Video Experience with{" "}
              <Typography
                variant="h1"
                component="span"
                color="primary"
                display="inline"
              >
                Equis
              </Typography>
            </Typography>

            <Typography variant="h6" sx={{ marginTop: "16px", width: "80%" }}>
              Transform your streaming experience with our extension. Unlock new
              possibilities, personalize your workflow, and elevate your viewing
              and creating journey.
            </Typography>
            {/* Add more subtitles, buttons, etc. */}
            <Box sx={{ marginTop: "32px" }}>
              <a
                href="https://chrome.google.com/webstore/detail/equis/pfdgldoldcfnafbkbicldhhkdebjnlcm"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    marginRight: "16px",
                    textTransform: "none",
                    fontWeight: "600",
                  }}
                >
                  Install for{" "}
                  <Typography
                    component="span"
                    variant="body1"
                    fontWeight="bold"
                    display="inline"
                    sx={{
                      marginLeft: "4px",
                      textTransform: "none",
                      fontWeight: "600",
                    }}
                  >
                    Chrome
                  </Typography>
                </Button>
              </a>

              <Button
                variant="outlined"
                color="secondary"
                sx={{ textTransform: "none", fontWeight: "600" }}
              >
                Learn More
              </Button>
            </Box>
            <Typography variant="body2" fontSize={"10px"} marginTop="16px">
              By installing, you acknowledge that you have read and understood
              our{" "}
              <Typography
                sx={{
                  textDecoration: "none",
                  color: { color: theme.palette.secondary.main },
                }}
                variant="body2"
                component="span"
                display="inline"
                fontSize={"10px"}
              >
                <Link
                  to="/privacy-policy"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Privacy Policies
                </Link>
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Particles id="tsparticles" />
    </div>
  );
}

export default HomePage;
